import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/LoginPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    props: true,
    component: Login,
  },
  {
    path: '/platform',
    name: 'Platform',
    props: true,
    component: () => import('../views/SupportPlatform.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        props: true,
        component: () => import('../views/DashboardHome.vue')
      },
      {
        path: 'dashboard2',
        name: 'Dashboard2',
        props: true,
        component: () => import('../views/DashboardPlane.vue')
      },
      {
        path: 'sensor-logs/:farm_pk',
        name: 'SensorLog',
        props: route => ({
          farm_pk: route.params.farm_pk,
          infoMic: route.query.infoMic,
          infoCam: route.query.infoCam,
          infoFeed: route.query.infoFeed,
          sensorId: route.query.sensorId
        }),
        component: () => import('../views/SensorLog.vue'),
      },
      {
        path: 'maintenance',
        name: 'Maintenance',
        props: true,
        component: () => import('../views/MaintenanceDevices.vue'),
        redirect: {
          name: 'CamMaintenance'
        },
        children: [
          {
            path: 'smartcam',
            name: 'CamMaintenance',
            component: () => import('../components/CamMaintenance.vue'),
          },
          {
            path: 'smartmic',
            name: 'MicMaintenance',
            component: () => import('../components/MicMaintenance.vue'),
          },
          {
            path: 'smartfeed',
            name: 'FeedMaintenance',
            component: () => import('../components/FeedMaintenance.vue'),
          },
        ],
      },
      {
        path: 'administration',
        name: 'Administration',
        props: true,
        component: () => import('../views/PecsmartAdministration.vue'),
        redirect: {
          name: 'CompaniesControl'
        },
        children: [
          {
            path: 'companies',
            name: 'CompaniesControl',
            component: () => import('../components/Adminsitration/CompanyControl.vue')
          },
          {
            path: 'barns',
            name: 'BarnsControl',
            component: () => import('../components/Adminsitration/BarnControl.vue')
          },
          {
            path: 'farms',
            name: 'FarmsControl',
            component: () => import('../components/Adminsitration/FarmControl.vue')
          },
          {
            path: 'batches',
            name: 'BatchesControl',
            component: () => import('../components/Adminsitration/BatchesControl.vue')
          },
          {
            path: 'silos',
            name: 'SilosControl',
            component: () => import('../components/Adminsitration/SiloControl.vue')
          },
          {
            path: 'sensors',
            name: 'SensorsControl',
            component: () => import('../components/Adminsitration/SensorControl.vue')
          },
          {
            path: 'silo-settings',
            name: 'SiloSettings',
            component: () => import('../components/Adminsitration/SiloSettingsControl.vue')
          },
          {
            path: 'company-settings',
            name: 'CompanySettings',
            component: () => import('../components/Adminsitration/CompanySettingsControl.vue')
          },
          {
            path: 'smartfeed-allocation',
            name: 'SmartFeedAllocation',
            component: () => import('../components/Adminsitration/SmartfeedAllocationControl.vue')
          },
          {
            path: 'animal-type',
            name: 'AnimalType',
            component: () => import('../components/Adminsitration/AnimalTypeControl.vue')
          }
        ]
      },
      {
        path: 'check',
        name: 'Acquisition',
        props: true,
        component: () => import('../views/CheckLastSiloProfileView.vue'),
        redirect: {
          name: 'AcquisitionContainer'
        },
        children: [
          {
            path: 'siloProfile',
            name: 'AcquisitionContainer',
            component: () => import('../components/CheckSiloProfile.vue')
          }
        ]
      },
      {
        path: 'register',
        name: 'RegisterControl',
        props: true,
        component: () => import('../views/RegisterControlView.vue'),
        redirect: {
          name: 'RegisterControlContainer'
        },  
        children: [
          {
            path: 'fluxRegister',
            name: 'FluxRegister',
            component: () => import('../components/registerControl/FluxRegisterWizard.vue')
          },
          {
            path: 'control',
            name: 'RegisterControlContainer',
            component: () => import('../components/registerControl/RegisterControlContainer.vue')
          }
        ]
      },
      {
        path: 'register',
        name: 'Registration',
        props: true,
        component: () => import('../views/RegisterInformation.vue'),
        redirect: {
          name: 'RegisterUser'
        },
        children: [
          {
            path: 'user',
            name: 'RegisterUser',
            props: true,
            component: () => import('../components/RegisterUser.vue')
          },
          {
            path: 'company',
            name: 'RegisterCompany',
            props: true,
            component: () => import('../components/RegisterCompany.vue')
          },
          {
            path: 'companySettings',
            name: 'RegisterCompanySettings',
            props: true,
            component: () => import('../components/RegisterCompanySettings.vue')
          },
          {
            path: 'farm',
            name: 'RegisterFarm',
            props: true,
            component: () => import('../components/RegisterFarm.vue')
          },
          {
            path: 'barn',
            name: 'RegisterBarn',
            props: true,
            component: () => import('../components/RegisterBarn.vue')
          },
          {
            path: 'silo',
            name: 'RegisterSilo',
            props: true,
            component: () => import('../components/RegisterSilo.vue')
          },
          {
            path: 'food',
            name: 'RegisterFood',
            props: true,
            component: () => import('../components/RegisterFood.vue')
          },
          {
            path: 'smartcam',
            name: 'RegisterSmartCam',
            props: true,
            component: () => import('../components/RegisterSmartCam.vue')
          },
          {
            path: 'smartmic',
            name: 'RegisterSmartMic',
            props: true,
            component: () => import('../components/RegisterSmartMic.vue')
          },
          {
            path: 'smartfeed',
            name: 'RegisterSmartFeed',
            props: true,
            component: () => import('../components/RegisterSmartFeed.vue')
          },
          {
            path: 'stall',
            name: 'RegisterStall',
            props: true,
            component: () => import('../components/RegisterStall.vue')
          },
          {
            path: 'batch',
            name: 'RegisterBatch',
            props: true,
            component: () => import('../components/RegisterBatch.vue')
          },
          {
            path: 'reference-weight-curve',
            name: 'RegisterReferenceWeightCurve',
            props: true,
            component: () => import('../components/RegisterReferenceWeightCurve.vue')
          },
        ]
      },
      {
        path: 'installation',
        name: 'Installation',
        props: true,
        component: () => import('../views/InstallationDevices.vue'),
        redirect: {
          name: 'InstallSmartCam'
        },
        children: [
          {
            path: 'smartcam',
            name: 'InstallSmartCam',
            component: () => import('../components/InstallSmartCam.vue'),
          },
          {
            path: 'smartmic',
            name: 'InstallSmartMic',
            component: () => import('../components/InstallSmartMic.vue'),
          },
          {
            path: 'smartfeed',
            name: 'InstallSmartFeed',
            component: () => import('../components/InstallSmartFeed.vue'),
          },
          {
            path: 'smartfeed2',
            name: 'InstallSmartFeed2',
            component: () => import('../components/installation/InstallSmartFeed2.vue'),
          },
        ],
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('../views/NotificationsView.vue'),
        children: [
          {
            path: '',
            redirect: { name: 'NotificationsControl' }
          },
          {
            path: 'settings',
            name: 'NotificationsSettings',
            component: () => import('../views/SettingsNotifications.vue')
          },
          {
            path: 'control',
            name: 'NotificationsControl', 
            component: () => import('../views/NotificationsControl.vue')
          }
        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
